import React from 'react';
import FooterOne from '../../common/footer/FooterOne';
import HeaderSzeles from '../../common/header/HeaderSzeles';
import SEO from '../../common/SEO';
import BannerSzeles from '../../component/banner/BannerSzeles';
import ProjectShowCase from '../../component/blog/ProjectsShowcase';
import ClientsSzeles from '../../component/brand/ClientsSzeles';
import ProjectSzeles from '../../component/project/ProjectSzeles';
import TestimonialSzeles from '../../component/testimonial/TestimonialSzeles';

const Home = () => {

    return (
        <div className=''>
        <SEO title="Realty and Development Inc."/>
        <main className="main-wrapper">
        <HeaderSzeles />
        <BannerSzeles />
        <ProjectSzeles />
        <TestimonialSzeles />
        <ClientsSzeles />
        <ProjectShowCase />
        <FooterOne />
        </main>
        </div>
    )
}

export default Home;

