
import React from 'react';

export default function EmailForm(props) {
    
    const { fieldLabel, inputType, name, fieldProp, error, styling, id, value, handleClick } = props

    return (
      <div className={styling ? styling : "col-sm-6"}> 
      <div className="form-group">
        <label className="col-form-label">{fieldLabel} {error}</label>
        <input className="form-control" type={inputType} id={id} name={name} value={value} 
        onChange={handleClick}
        {...fieldProp}/>
      </div>
    </div>
    )
}