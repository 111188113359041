import React, { useState } from "react";
import ProjectPropOne from "./../itemProp/ProjectPropOne";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import UpcomingProjectData from "../../../data/project/UpcomingProjectData.json";

const AllData = UpcomingProjectData;

const UpComingProjects = ({ colSize, parentClass, perPageShow }) => {
  const [projects] = useState(AllData.slice(0, 16));
  const [pageNumber, setPageNumber] = useState(0);

  const projectPerPage = perPageShow ? perPageShow : 6;
  const pageVisited = pageNumber * projectPerPage;

  const displayProjects = projects
    .slice(pageVisited, pageVisited + projectPerPage)
    .map((data) => (
      <div className="col" key={data.id}>
        <ProjectPropOne projectStyle="" portfolio={data} />
      </div>
    ));

  const pageCount = Math.ceil(projects.length / projectPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className={` ${parentClass ? parentClass : ""}`}>
      <div className="container-fluid plr--30">
        <div className="section " id="section1" style={{ marginTop: 260,  }}>
          <div className="container">
            <div
              style={{
                textAlignment: "heading-left",
                color: "#e09f1d",
                fontWeight: "bold",
                fontSize: "6vh",
              }}
            >
              Upcoming Projects
            </div>

            <div
              className={`row row-cols-1 ${
                colSize ? colSize : "row-cols-sm-2 row-cols-xl-3"
              }`}
              style={{ fontSize: "large" }}
            >
              {displayProjects}
            </div>

            <ReactPaginate
              previousLabel={<FaArrowLeft />}
              nextLabel={<FaArrowRight />}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"pagination"}
              previousLinkClassName={"prev"}
              nextLinkClassName={"next"}
              disabledClassName={"disabled"}
              activeClassName={"current"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpComingProjects;
