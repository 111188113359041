

import React from 'react';


const ProjectPropSzeles = ({projectStyle, portfolio}) => {
	
    return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div className="thumbnail">
				<div>
					<img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
				</div>
				</div>
				<div className="content">
			
				
				</div>
				<h4 className="title" style={{paddingTop:20, }}> 
					<div>{portfolio.title}</div>
				</h4>
			</div>
		</>
    )
}

export default ProjectPropSzeles;