import React from "react";

const ServiceProp = ({ colSize, serviceStyle, serviceData }) => {
  return (
    <>
      {serviceData.map((data, index) => (
        <div className={colSize} key={index}>
          <div className={`services-grid ${serviceStyle}`}>
            <div className="container" style={{ height: 150 }}>
				<div className="row">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img src={process.env.PUBLIC_URL + data.image} alt="icon" />
                </div>
                
              </div>
            </div>
			</div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="content">
                   
					<div className="col-12">
                  <h5 className="title">
                    <center>
                      <div style={{ fontSize: "25px" }}>{data.title}</div>
                    </center>
                  </h5>
                </div>
				<div className="" style={{ color: "black" }}>
                      {data.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServiceProp;
