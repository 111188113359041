import React from 'react';


const SectionTitle = ({subtitle, title, description, textAlignment, textColor,image}) => {
    return (
        <div className={`section-heading ${textAlignment} ${textColor}`}>
            <img src={image } alt=""/>
            <div className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} style={{color: 'black'}}></div>
            <h2 className="title" dangerouslySetInnerHTML={{__html: title}} style={{color:'black'}}></h2>
            <p dangerouslySetInnerHTML={{__html: description}} style={{color:'black'}}></p>
        </div>
    )
}

export default SectionTitle;