import React from "react";
/* Header and Footer */
import HeaderSzeles from "../../common/header/HeaderSzeles";
import FooterOne from "../../common/footer/FooterOne";

import SEO from "../../common/SEO";

/* Body Contents */
import FinishedProjects from "../../component/project/itemProp/FinishedProjects"
import BannerProject from "../../elements/breadcrumb/Szeles/BannerProjects";
import UpComingProjects from "../../component/project/itemProp/UpComingProjects";

const Projects = () => {
  return (
    <div className="">
      <SEO title="Projects" />

      <main className="main-wrapper">
        <HeaderSzeles />

        <BannerProject />
        <FinishedProjects />
        <UpComingProjects />

        <FooterOne parentClass="" />
      </main>
    </div>
  );
};

export default Projects;
