import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import TestimonialPropOne from './TestimonialPropOne';

const TestimonialSzeles = () => {
    return (
        <div className="section section-padding">
            <div className="container">
                <SectionTitle 
                    subtitle="Testimonial"
                    title="From getting started"
                    description="Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate augue sed eu leo eget risus imperdiet."
                    textAlignment="heading-left"
                    textColor=""
                />
                <div className="row">
                    <TestimonialPropOne colSize="col-lg-4" itemShow="3"/>
                </div>
            </div>
            <ul className="list-unstyled shape-group-13">
                  <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-40.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-4">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-2">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-1">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-31.png"}
                      alt="Circle"
                    />
                  </li>
                </ul>
        </div>
    )
}

export default TestimonialSzeles;