import React from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaPhone,
  FaMobileAlt,
} from "react-icons/fa";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton style={{color:"whitesmoke", }}></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row " style={{color:"whitesmoke" ,}}>
          <div className="col-lg-5 col-xl-6">
            <ul className="main-navigation list-unstyled">
              <li>
                <Link to={process.env.PUBLIC_URL + "/" }style={{color:"whitesmoke" ,}}>Home</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/about"}style={{color:"whitesmoke" ,}}>About Us</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/services"}style={{color:"whitesmoke" ,}}>
                  Our Services
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/projects"}style={{color:"whitesmoke" ,}}>Projects</Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/Contact-Us"}style={{color:"whitesmoke" ,}}>
                  Contact{" "}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <span className="title" style={{color:"whitesmoke" ,}}>Contact Information</span>
                  <ul>
                  <FaPhone /> Tel. No. (02) 86715246
                  </ul>
                  <span className="title" style={{color:"whitesmoke" ,}}>Mobile Numbers</span>
                  <ul>
    
                      {" "}
                      <FaMobileAlt />
                      (0917) 958 -2699
                    </ul>
                    <ul>
                      {" "}
                      <FaMobileAlt />
                      (0977) 266 -3665
                    </ul>
                    <ul>
                      {" "}
                      <FaMobileAlt />
                      (0915) 572 -7764
                    </ul>
                    <ul>
                      {" "}
                      <FaMobileAlt />
                      (0977) 255 9495
                   
                  </ul>
                </address>
                <address className="address">
                  <span className="title" style={{color:"whitesmoke" ,}}>
                    We're Always Available . Call Now.
                  </span>
                </address>
              </div>
              <div className="contact-inner">
                <h5 className="title" style={{color:"whitesmoke" ,}}>Find us here</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <a href="https://facebook.com/">
                        <FaFacebookF />
                      </a>
                    </li>

                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
