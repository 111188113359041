import React from "react";

const Data = [
  {
    image: "/images/brand/brand-1.png",
  },
  {
    image: "/images/brand/brand-2.png",
  },
  {
    image: "/images/brand/brand-3.png",
  },
  {
    image: "/images/brand/brand-4.png",
  },
  {
    image: "/images/brand/brand-5.png",
  },
  {
    image: "/images/brand/brand-6.png",
  },
  {
    image: "/images/brand/brand-7.png",
  },
  {
    image: "/images/brand/brand-8.png",
  },
  {
    image: "/images/brand/brand-8.png",
  },
  {
    image: "/images/brand/brand-8.png",
  },
  {
    image: "/images/brand/brand-8.png",
  },
];

const BrandItem = () => {
  return (
    <>
      {Data.map((data, index) => (
        <div className="col-lg-2 col-md-6 col-sm-3 " key={index} >
          <div className="brand-grid">
            <center>
            <img src={process.env.PUBLIC_URL + data.image} alt="Brand" style={{ borderRadius: 8,  }} />
            </center>
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandItem;
