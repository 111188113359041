import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BrandItem from './BrandItem';


const ClientsSzeles = () => {
    return (
        <div className="section section-padding-2 ">
        <div className="container" >
            <SectionTitle 
                subtitle="Top Clients"
                title="We’ve built solutions for..."
                description="Design anything from simple icons to fully featured websites and applications."
                textAlignment="heading-light-left"
                textColor=""
            />
            <div className='row' >
               <BrandItem />
            </div>
        </div>
        <ul className="list-unstyled shape-group-14" >
            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="Circle" /></li>
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Circle" /></li>
            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Circle" /></li>
            <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-2.png"} alt="Circle" /></li>
        </ul>
    </div>
    )
}

export default ClientsSzeles;