import React, { useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import EmailForm from "./EmailForm";
import Swal from "sweetalert2";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormTwo = () => {
  const form = useRef();

  const [result,] = useState(false);

  /* State Variables */

  const [Name, setName] = useState("");

  const [Email, setEmail] = useState("");

  const [Phone, setPhone] = useState("");

  const [Text, setText] = useState("");

  /*On-click Function */

  const handleClick = (e) => {
    e.preventDefault();

    if (e.target.id === "Name") {
      setName(e.target.value);
    }
    if (e.target.id === "Email") {
      setEmail(e.target.value);
    }
    if (e.target.id === "Phone") {
      setPhone(e.target.value);
    }
    if (e.target.id === "Text") {
      setText(e.target.value);
    }
  };
  /* Payload Function */
  const getPayload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Name", Name);
    formData.append("Email", Email);
    formData.append("Phone", Phone);
    formData.append("Text", Text);

    fetch("http://127.0.0.1:8000/api/termination", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        Swal.fire("", "Application Successfully Sent", "success");

        setName("");
        setEmail("");
        setPhone("");
        setText("");
        form.current.value = "";
      })
      .catch((error) => {
        Swal.fire("Error", error);
      });
  };

  /* const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_yj5dgzp",
        "template_hfduayo",
        form.current,
        "WLENsTkBytC0yvItS"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);
 */
  return (
    <form method="POST" onSubmit={getPayload}>
      <EmailForm
        styling={"col-sm-12"}
        fieldLabel={"Name:"}
        inputType={"text"}
        name={"Name"}
        id={"Name"}
        value={Name}
        handleClick={handleClick}
      />

      <EmailForm
        styling={"col-sm-12"}
        fieldLabel={"Email:"}
        inputType={"text"}
        name={"Email"}
        id={"Email"}
        value={Email}
        handleClick={handleClick}
      />
      <EmailForm
        styling={"col-sm-12"}
        fieldLabel={"Phone:"}
        inputType={"Number"}
        name={"Phone"}
        id={"Phone"}
        value={Phone}
        handleClick={handleClick}
      />

      <EmailForm
        styling={"col-lg-12 col-md-12"}
        fieldLabel={"How Can we Help You:"}
        inputType={"text"}
        name={"Text"}
        id={"Text"}
        value={Text}
        handleClick={handleClick}
      />

      <div className="submit-section">
        <button
          className="btn  submit-btn"
          style={{
            color:"whitesmoke",
            backgroundColor: "#e09f1d",
            width: "20vh",
            fontSize: "20px",
            fontWeight: "bold",
            marginTop:"34px",

          }}
        >
          Submit
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormTwo;
