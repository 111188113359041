import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const BannerSzeles = () => {
  return (
    <div
      className=""
      style={{ height: "100vh",paddingTop:"130px"  }}
    >
      
      <div className="">
        <div className="row align-items-end align-items-xl-start">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <div className="banner-content">
                      <div className="">
                        
                          &nbsp;
                        </div>
                        <div className="container">
                          <div
                            className="row align-items-end align-items-xl-start"
                            style={{
                              position: "absolute",
                              paddingTop: "150px",
                            }}
                          >
                            <div className="col-12" style={{marginTop:"250px", }}>
                              <div className="">
                                <AnimationOnScroll
                                  animateIn="fadeInUp"
                                  animateOnce={true}
                                  delay={100}
                                >
                                  <h1
                                    style={{
                                      color: "#e09f1d",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    One of the best<br />  Construction Company
                                  </h1>
                                  <h6
                                    className="subtitle"
                                    style={{
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    SZELES Realty & Development Inc has been
                                    awarded and trusted with multiple features
                                    on design,construction,<br />project
                                    management,interior renovaion for
                                    residential and commercial structures.
                                  </h6>
                                </AnimationOnScroll>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="aboutUsBgImg">
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default BannerSzeles;
