import React, { useState } from "react";
import Logo from "../../elements/logo/Logo";
import Navigation from "./Navigation";
import OffcanvasMenu from "./OffcanvasMenu";
import StickyHeader from "./StickyHeader";

import MobileMenu from "./MobileMenu";

const HeaderSzeles = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const OffcanvasHandleClose = () => setShowOffcanvas(false);
  const OffcanvasHandleShow = () => setShowOffcanvas(true);

  const sticky = StickyHeader(100);

  const MobileMenuHandler = () => {
    document.querySelector(".mobilemenu-popup").classList.toggle("show");
    document.querySelector("body").classList.toggle("mobilemenu-show");

    var elements = document.querySelectorAll(
      ".mobilemenu-popup .menu-item-has-children > a"
    );

    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {};
      }
    }
  };

  return (
    <>
      <header className="header axil-header header-style-2">
        <div className={`axil-mainmenu ${sticky ? "axil-sticky" : ""}`}>
          <div className="">
            <div className="header-navbar">
              <div className="header-logo" style={{height: 100}}>
                <Logo 
                  limage="/images/Szeles/szeles-logo-resized.png"
                  dimage="/images/Szeles/szeles-logo-resized.png"
                  simage="/images/Szeles/szeles-logo-resized.png"
                />
              </div>
              <div className="header-main-nav">
                <Navigation />
              </div>
              <div className="header-action">
                <ul className="list-unstyled">
                  <li className="sidemenu-btn d-lg-block d-none">
                    <button
                      className="btn-wrap btn-dark"
                      onClick={OffcanvasHandleShow}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </li>
                  <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                    <button
                      className="btn-wrap btn-dark"
                      onClick={MobileMenuHandler}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <OffcanvasMenu
        offcanvasShow={showOffcanvas}
        offcanvasHide={OffcanvasHandleClose}
      />
      <MobileMenu MobileHandler={MobileMenuHandler} />
    </>
  );
};

export default HeaderSzeles;
