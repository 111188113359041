import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const AboutSzeles = () => {
  return (
    <div className="section section-padding-equal  case-study-featured-area" style={{marginTop:140, }} >
      <div className="container" >
        <div className="row">
        
          <div className="col-4  ">
            <div className="case-study-featured-thumb text-start" style={{marginTop:70,  }}>
              <AnimationOnScroll
                animateIn="zoomIn"
                duration={2}
                delay={300}
                animateOnce={true}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/Szeles/about-us/MissionVision.png"
                  }
                  alt="Mission and Vision"
                />
              </AnimationOnScroll>
            </div>
          </div>
          <div className="col-8">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle" style={{paddingTop:80,}}>Who we are</span>
                <h3 className="title">Background:</h3>
                <div style={{ textAlign: "justify" }}>
                  <p>
                    SZELES Realty & Development Inc. evolved from a group of
                    individuals with interest on real state business and a
                    background in Engineering & construction focusing on
                    residential and commercial structures.
                  </p>
                  <h3 className="title">Status:</h3>
                  <p>
                    Today, SZELES Realty & Development Inc has been awarded and
                    trusted with a variety of contracts for design,
                    construction, project management, interior renovation of
                    residential and commercial structures obtained through
                    bidding and referrals from our satisfied clients. Backed by
                    a professional team of Engineers, Architects, Project
                    Managers, Interior Designers, and Skilled Personnel.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      {/* Mission Vission */}

      <div className="container"  >
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" >
            {/* container of mission */}
            <div className="row">
              <div className="col-3">
                <AnimationOnScroll
                  animateIn="zoomIn"
                  duration={2}
                  delay={300}
                  animateOnce={true}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/Szeles/about-us/flag.png"
                    }
                    alt="Mission "
                    
                  />
                </AnimationOnScroll>
              </div>
              <div className="col-9" style={{ textAlign: "justify", paddingTop:30, }}>
                {" "}
                <h3>Mission:</h3>
                <div classname="" style={{fontSize:"16px", color:"black" }}>
                  SZELES Realty & Development Inc. evolved from a group of
                  individuals with interest on real state business and a
                  background in Engineering & construction focusing on
                  residential and commercial structures.
                  </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12"  >
            
            {/* container of Vision */}
            <div className="row">
              <div className="col-3">
                <AnimationOnScroll
                  animateIn="zoomIn"
                  duration={2}
                  delay={300}
                  animateOnce={true}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/Szeles/about-us/Vision.png"
                    }
                    alt=" Vision"
                  />
                </AnimationOnScroll>
              </div>
              <div className="col-9" style={{ textAlign: "justify",paddingTop:30,  }}>
                {" "}
                <h3>Vision:</h3>
                <p>
                  SZELES Realty & Development Inc. evolved from a group of
                  individuals with interest on real state business and a
                  background in Engineering & construction focusing on
                  residential and commercial structures.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TESTING INSERT UP */}
      <ul className="list-unstyled shape-group-14">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3" >
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-6.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2" >
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutSzeles;
