import React from "react";
import LocationData from "../../data/contact/LocationData.json";

const allData = LocationData;

const ContactLocation = () => {
  return (
    <>
      {allData.map((data, index) => (
        <div className="col-lg-2 col-xl-6"  >
          <div className="office-location" >
            <div className="thumbnail">
              <img src={process.env.PUBLIC_URL + data.thumb} alt="Office" style={{borderRadius: 13, }} />
            </div>
            <div className="content">
              <h4 className="title" style={{color:"black", }}>{data.title}</h4>
              <p>{data.address}</p>
            </div>
          </div>
        </div>
        
      ))}
    </>
  );
};

export default ContactLocation;
