import React from "react";
/* Header and Footer */
import HeaderSzeles from "../../common/header/HeaderSzeles";
import FooterOne from "../../common/footer/FooterOne";

import SEO from "../../common/SEO";

/* Body Contents */
import AboutUsBanner from "../../elements/breadcrumb/Szeles/AboutUsBanner";
import AboutSzeles from "../../component/about/Szeles/AboutSzeles";
import TeamSzeles from "../../component/team/SzelesTeam/TeamSzeles";

const About = () => {
  return (
    <div className="">
      <SEO title="About us" />
     
      <main className="main-wrapper">
        <HeaderSzeles />
        <AboutUsBanner />
        <AboutSzeles />
       
        <TeamSzeles />
        
        <FooterOne parentClass="" />
      </main>
    </div>
  );
};

export default About;
