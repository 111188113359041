import React from 'react';
/* Header Footer */
import HeaderSzeles from "../../common/header/HeaderSzeles";

import SzelesServiceBanner from "../SzelesServiceBanner";
import FooterOne from "../../common/footer/FooterOne";

import SEO from "../../common/SEO";

/* Body Contents */

import SlezesServices from "../Szeles/SzelesServices";

const Services = () => {
  return (
    <div className="">
      <SEO title="Services" />
      
      <main className="main-wrapper">
       
        <HeaderSzeles />
        <SzelesServiceBanner />
        <SlezesServices />
        
        <FooterOne parentClass="" />
      </main>
    </div>
  )
}

export default Services