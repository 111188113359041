import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const BannerSzeles = () => {
  return (
    <div className="" style={{height:"100vh", marginTop:260, }}>
    <div className="container">
      <div className="row align-items-end align-items-xl-start">


      <div className="container" >
      <div className="row">
        <div className="col-lg-12 col-sm-12" >
          <div className="row">

        <div className="col-12">
          <div className="banner-content">
          <div className="">
                      <div
                      
                      >
                        &nbsp;
                      </div>
                      <div className="container">
                        <div
                          className="row align-items-end align-items-xl-start"
                          style={{
                            position: "absolute",
                            paddingTop: "150px",
                          }}
                        >
                          <div className="col-lg-12">
                            <div className="">
                              <AnimationOnScroll
                                animateIn="fadeInUp"
                                animateOnce={true}
                                delay={100}
                              >
                                <h1
                                  style={{
                                    color: "#e09f1d",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Szeles Realty and Development Inc.
                                </h1>
                                <h6
                                  className="subtitle"
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Magni dolores eos qui ratione voluptatem sequi nesciunt
                                </h6>
                              </AnimationOnScroll>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="video-background">
                        
                        <img
                          style={{
                            marginTop:"-30px",
                            height: "500px",
                            objectFit:"cover",
                            opacity: "20%",
                            borderRadius: 20,

                            
                          }}
                          src="/images/Szeles/szeles-logo.png"
                          alt="Home"
                        />
                        
                      </div>
                    </div>
          </div>
        </div>
       

      </div>
    </div>

    </div>
    </div>
    </div>
    </div>
     {/*  <ul className="list-unstyled shape-group-21">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"}
            alt="Bubble"
          />
        </li>
        
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-4">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-5">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-6">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-7">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"}
            alt="Bubble"
          />
        </li>
      </ul> */}
    </div>
  );
};

export default BannerSzeles;
