import React from "react";
import TeamData from "../../../../src/data/team/TeamData.json";

const allData = TeamData;

const TeamSzeles = () => {
  return (
    <div className="section section-padding  ">
      <div className="container">
        <div className="section-heading heading-light">
          <span className="subtitle">Our valus</span>
          <h2 className="title mb--50" style={{ color: "black" }}>
            Meet The Team
          </h2>
          <p>
            Nulla facilisi. Nullam in magna id dolor blandit rutrum eget
            vulputate augue sed eu leo eget risus imperdiet.
          </p>
        </div>
        <div className="row">
          {allData.map((data) => (
            <div className="col-lg-2 col-md-3 col-xs-1" key={data.id}>
              <div className="team-grid">
                <div className="thumbnail">
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + data.thumb}
                      alt={data.title}
                      style={{ borderRadius: 10 }}
                    />
                  </div>
                </div>
                <div className="content">
                  <h4 className="title">
                    <div style={{ color: "black" }}>{data.title}</div>
                  </h4>
                  <span
                    className="designation"
                    dangerouslySetInnerHTML={{ __html: data.designation }}
                  ></span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-14">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3" >
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-2.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-2" >
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-6.png"}
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default TeamSzeles;
