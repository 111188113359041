import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const ContactsSzeles = () => {
  return (
    <div className="" style={{ paddingTop: "156px" }}>
      <div className="">
        <div className="row align-items-end align-items-xl-start">
          <div className="">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <div className="banner-content">
                      <div className="">
                        <div
                          style={{
                            position: "absolute",
                          }}
                        >
                          &nbsp;
                        </div>
                        <div className="container">
                          <div
                            className="row align-items-end align-items-xl-start"
                            style={{
                              position: "absolute",
                              paddingTop: "150px",
                            }}
                          >
                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="" style={{ marginTop: "250px" }}>
                                <AnimationOnScroll
                                  animateIn="fadeInUp"
                                  animateOnce={true}
                                  delay={100}
                                >
                                  <h1
                                    style={{
                                      color: "#e09f1d",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Hurry up, pick up the phone and <br />
                                    contact us right away
                                  </h1>
                                  <h6
                                    className="subtitle"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      alignContent: "",
                                    }}
                                  >
                                    ita distinctio. Nam libero tempore, cum
                                    soluta nobis est eligendi
                                    <br /> optio cumque nihil impedit quo minus
                                    id quod maxime placeat facere possimus,
                                    omnis voluptas assumenda
                                  </h6>
                                </AnimationOnScroll>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="contactUsBgImg"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsSzeles;
