import React from 'react';
import BlogData from "../../data/blog/BlogData.json";


const allBlogData = BlogData;

const BlogListOne = ({colSize, itemShow}) => {
    return (
        <>
            {allBlogData.slice(0, itemShow).map((data) => (
                <div className={`${colSize}`} key={data.id}>
                    <div className={`blog-list ${(data.id % 2  === 0) ? "border-start" : ""}`}>
                        <div className="post-thumbnail" >
                            <div >
                                <img src={`${process.env.PUBLIC_URL}/${data.thumb}`} alt="Blog Post" style={{ borderRadius: 8 }} />
                            </div>
                        </div>
                        <div className="post-content">
                            <h5 className="title">
                                <div >
                                    {data.title}
                                </div>
                            </h5>
                            <p>{data.title2}</p>
                            <p>{data.excerpt}</p>
                            
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default BlogListOne;