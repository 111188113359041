import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const BannerProject = () => {
  return (
    <div
      className=""
      style={{ height: "100vh",paddingTop:"130px"  }}
    >
      <div className="">
        <div className="row align-items-end align-items-xl-start">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <div className="banner-content">
                      <div className="r">
                        
                          &nbsp;
                        </div>
                        <div className="container">
                          <div
                            className="row align-items-end align-items-xl-start"
                            style={{
                              position: "absolute",
                              paddingTop: "150px",
                            }}
                          >
                            <div className="col-12">
                              <div className="" style={{marginTop:"250px", }}>
                                <AnimationOnScroll
                                  animateIn="fadeInUp"
                                  animateOnce={true}
                                  delay={100}
                                >
                                  <h1
                                    style={{
                                      color: "#e09f1d",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Here are our Projects
                                  </h1>
                                  <h6
                                    className="subtitle"
                                    style={{
                                      color: "light-grey",
                                      fontWeight: "bold",
                                      padding:3
                                    }}
                                  >
                                   ero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis polo,rum fuga. Et harum quidem rerum facilis est et<br /> expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo 
                                  </h6>
                                </AnimationOnScroll>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="backgroundImg">
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default BannerProject;
