import React from 'react';
import { Link } from 'react-router-dom';


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <Link to="/"> Home </Link>
                    
                </li>
                <li className="menu-item-has-children">
                    <Link to="/about">About us </Link>
                    <ul className="axil-submenu">
                        
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="/services">Our Services </Link>
                   
                </li>
                <li className="menu-item-has-children">
                    <Link to="/projects">Projects</Link>
                    
                </li>
                <li className="menu-item-has-children">
                    <Link to="/Contact-Us">Contact Us </Link>
                    
                </li>
              
            </ul>
        </nav>
    )
}

export default Nav;