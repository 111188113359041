import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectData from "../../data/project/ProjectData.json";

import ProjectPropSzeles from "./itemProp/ProjectPropSzeles";

const portfolioData = ProjectData;

const ProjectSzeles = () => {
  return (
    <div className="section">
      <div className="container">
        <SectionTitle
          subtitle="Services"
          title="Our Experties"
          description="Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate augue eget risus imperdiet."
          textAlignment="heading-light-left mb--90"
          textColor="black"
        />

        <div className="row row-45">
          {portfolioData.slice(0, 5).map((data) => (
            <div className="col-lg-6 col-md-6 col-sm-8" key={data.id}>
              <ProjectPropSzeles
                projectStyle="project-style-2"
                portfolio={data}
              />
            </div>
          ))}
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-2.png"} alt="bubble" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Circle" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="bubble" /></li>
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="bubble" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-2.png"} alt="bubble" /></li>
            </ul>
    </div>
  );
};

export default ProjectSzeles;
