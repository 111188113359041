import React from "react";
import SEO from "../../common/SEO";
import FormTwo from "../../component/contact/FormTwo";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ContactLocation from "../../component/contact/ContactLocation";
import {  FaMobileAlt, FaPhone } from "react-icons/fa";

const SzelesContacts = () => {
  return (
    <>
      <SEO title="Contact Us" />
      <main className="main-wrapper">
        <div className="section section-padding" style={{marginTop:80,}}>
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">Send us an Email right now</h3>
                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-5 col-lg-12 offset-xl-1">
                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150 ">
                  <h2 className="title">Contacts</h2>
                  <p>
                    Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm
                  </p>
                  <h5 style={{color:"white"}}>Telephone Number:</h5>

                  
                    <h6>
                      <a href="tel:1234567890">
                        {" "}
                        <FaPhone style={{height:"16px"}} /> : 02 86715246
                        
                      </a>
                    </h6>
                  

                  <h5 style={{color:"white"}}>Cellphone Numbers:</h5>
                  
                    <h6 style={{color:"white"}}>
                      <a href="tel:1234567890">
                        <FaMobileAlt />
                        (0917) 958 -2699{" "}
                      </a>
                    </h6>
                    <h6 style={{color:"white"}}>
                      <a href="tel:1234567890">
                        <FaMobileAlt />
                        (0977) 266 -3665
                      </a>
                    </h6>
                    <h6 style={{color:"white"}}>
                      <a href="tel:1234567890">
                        <FaMobileAlt />
                        (0915) 572 -7764{" "}
                      </a>
                    </h6>
                    <h6 style={{color:"white"}}>
                      <a href="tel:1234567890">
                        <FaMobileAlt />
                        (0977) 255 -9495
                      </a>
                    </h6>
                  
                </div>

                <div className="contact-info mb--30">
                  <h4 className="title">
                    Email{" "}
                   
                  </h4>
                  <p>
                    Our support team will get back to in 48-h during standard
                    business hours.
                  </p>
                  {" "}
                  <a
                    href="mailto:SZELESREALTYDEVELOPMENT@GMAIL.COM"
                    style={{ fontSize: "small" }}
                    
                  >
                     <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/Szeles/ContactUs/mail-resized.png"
                      }
                      alt="email"
                      style={{ height: 30, width: 30, paddingRight:6 }}
                    />
                    SZELESREALTYDEVELOPMENT@GMAIL.COM
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <div className="section section-padding  overflow-hidden">
          <div className="container">
            <SectionTitle
              subtitle="Find Us"
              title="Our office"
              description=""
              textAlignment="heading-light-left"
              textColor=""
            />
            <div className="row">
              <ContactLocation />
            </div>
          </div>
          <ul className="shape-group-11 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-6.png"}
                alt="line"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="line"
              />
            </li>
          </ul>
        </div>
      </main>
    </>
  );
};

export default SzelesContacts;
