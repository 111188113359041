import React from "react";

const PropOne = ({ projectStyle, portfolio }) => {
  return (
    <div className={`project-grid ${projectStyle}`}>
      <div className="thumbnail">
        <img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
      </div>
      <div className="content">
        <h4 className="title">
          <div style={{ height: 100, fontSize: "27px" }}>{portfolio.title}</div>
        </h4>

        <div className="">
          <div className="row ">
            <div className="col-2">
              <img src={portfolio.logo} alt="placeholder" />
            </div>
            <div className="col-8">
              <span
                className="subtitle"
                style={{ height: 100, fontSize: "20px" }}
              >
                {portfolio.category.map((cat, i) => (
                  <span key={i}>{cat}</span>
                ))}
              </span>
            </div>
          </div>
        </div>
      </div>
	  <ul className="list-unstyled shape-group-13">
                  <li className="shape shape-1">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-40.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-4">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-31.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-1">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-6.png"}
                      alt="Circle"
                    />
                  </li>
				  <li className="shape shape-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
				   <li className="shape shape-1">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
				  <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
				   <li className="shape shape-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
				  <li className="shape shape-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
				   <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
				  <li className="shape shape-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
                </ul>
    </div>
  );
};

export default PropOne;
