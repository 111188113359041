import React from 'react';
import { Link } from 'react-router-dom';


const Navigation = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu" >
                <li>
                    <Link to="/"><h5>Home</h5>  </Link>
                </li>
                <li>
                    <Link to="/about"><h5>About Us</h5>  </Link>
                </li>
                <li>
                    <Link to="/services"><h5>Our Servces</h5>  </Link>
                </li>
                <li>
                    <Link to="/projects"><h5>Projects</h5>  </Link>
                </li>

                <li>
                    <Link to={process.env.PUBLIC_URL + "/Contact-Us"}><h5>Contact</h5></Link>
                </li>

                
            </ul>
        </nav>
    )
}

export default Navigation;