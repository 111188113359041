import React from "react";

import SEO from "../../common/SEO";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ServiceProp from "../../component/service/ServiceProp";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";

const allData = ServiceData;

const ServiceOne = () => {
  const designData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "design-and-engineering"
  );
  const developmentData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "construction-management"
  );
  const marketingData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "interior-design"
  );
  const businessData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "land-development"
  );
  const technologyData = allData.filter(
    (data) => slugify(data.cate ? data.cate : "") === "property-management"
  );

  return (
    <>
      <SEO title="Service One" />

      <main className="main-wrapper">
        <div className="service-scroll-navigation-area">
          {/* Service Nav */}

          <nav
            id="onepagenav"
            className="service-scroll-nav navbar onepagefixed"
          >
            <div className="container" style={{ paddingTop: 50 }}>
              <ul className="nav nav-pills" style={{ paddingTop: 5 }}>
                <li className="nav-item">
                  <a className="nav-link" href="#section1">
                    Design & Engineering
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#section2">
                    Construction Management
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#section3">
                    Interior Design & Fit -Outs
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#section4">
                    Land Development/ Civil Works
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#section5">
                    Property Management
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <div className="section" id="section1" style={{ paddingTop: 130 }}>
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Design & Engineering"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-6 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={designData}
                />
                <ul className="list-unstyled shape-group-14">
                  <li className="shape shape-1">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/circle-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-3">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-5.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-1">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-2">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/line-2.png"}
                      alt="Circle"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="section  " id="section2" style={{ paddingTop: 50 }}>
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Construction Management"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-6 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={developmentData}
                />
                <ul className="list-unstyled shape-group-13">
                  <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-40.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-2">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-1">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
                      alt="Circle"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="section " id="section3" style={{ paddingTop: 50 }}>
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Interior Design & Fit
                -Outs"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              
              
              <div className="row">
                <ServiceProp
                  colSize="col-lg-6 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={marketingData}
                />
                <ul className="list-unstyled shape-group-13">
                  <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-40.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-3.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-3.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-2">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-31.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-1">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-13.png"}
                      alt="Circle"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="section  " id="section4" style={{ paddingTop: 50 }}>
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Land Development/
                Civil Works"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-6 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={businessData}
                />
                <ul className="list-unstyled shape-group-13">
                  <li className="shape shape-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-2.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-2">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/line-4.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-1">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-31.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-1">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-13.png"}
                      alt="Circle"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="section " id="section5" style={{ paddingTop: 50 }}>
            <div className="container">
              <SectionTitle
                subtitle="Service"
                title="Property Management"
                description=""
                textAlignment="heading-left"
                textColor=""
              />
              <div className="row">
                <ServiceProp
                  colSize="col-lg-6 col-md-6"
                  serviceStyle="service-style-2"
                  serviceData={technologyData}
                />
                <ul className="list-unstyled shape-group-13">
                  <li className="shape shape-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-2.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-4">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/others/bubble-31.png"
                      }
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-1">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-31.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-3">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-6.png"}
                      alt="Circle"
                    />
                  </li>
                  <li className="shape shape-5">
                    <img
                      src={process.env.PUBLIC_URL + "/images/others/bubble-3.png"}
                      alt="Circle"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ServiceOne;
