import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const SzelesServiceBanner = () => {
  return (
    <div className="" style={{  paddingTop: "155px", }}>
    <div className="">
      <div className="row align-items-end align-items-xl-start">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="row">
                <div className="col-12">
                  <div className="banner-content">
                    <div className="">
                      <div
                        style={{
                          position: "absolute",
                        }}
                      >
                        &nbsp;
                      </div>
                      <div className="container">
                        <div
                          className="row align-items-end align-items-xl-start"
                          style={{
                            position: "absolute",
                            paddingTop: "150px",
                          }}
                        >
                          <div className="col-12">
                            <div className="" style={{marginTop:"250px"}}>
                              <AnimationOnScroll
                                animateIn="fadeInUp"
                                animateOnce={true}
                                delay={100}
                              >
                                <h1
                                  style={{
                                    color: "#e09f1d",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Best Services at your convenience
                                </h1>
                                <h6
                                  className="subtitle"
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                  }}
                                >
                                  SZELES Realty & Development Inc has been
                                  awarded and trusted with multiple features
                                  on design,construction,
                                  <br />
                                  project management,interior renovaion for
                                  residential and commercial structures.
                                </h6>
                              </AnimationOnScroll>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ServicesBgImg">
                        
                      </div>
                      
                    </div>
                    
                  </div>
                </div>
                
              </div>
              
            </div>
            
          </div>
          
        </div>
        
      </div>
      
      
    </div>
    
        </div>
  );
};

export default SzelesServiceBanner;
