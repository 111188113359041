import React from 'react';
/* Header Footer */
import HeaderSzeles from "../../common/header/HeaderSzeles";
import FooterOne from "../../common/footer/FooterOne";

import SEO from "../../common/SEO";

/* Body Contents */

import SzelesContacts from './SzelesContacts';
import ContactsSzeles from '../../elements/breadcrumb/Szeles/ContactsSzeles';

const ContactUs = () => {
  return (
    <div className="">
      <SEO title="Contact Us" />
    
      <main className="main-wrapper">
       
        <HeaderSzeles />
        <ContactsSzeles />
        
        <SzelesContacts />
        
        <FooterOne parentClass="" />
      </main>
    </div>
  )
}

export default ContactUs;